@font-face {
  font-family: 'BlinkerBold';
  src: local('BlinkerBold'), url('../../theme/fonts/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratMedium';
  src: local('MontserratMedium'), url('../../theme/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'), url('../../theme/fonts/Montserrat-Bold.ttf') format('truetype');
}
